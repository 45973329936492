import React from 'react'

import { RecordChange } from '../../models'

interface HistoryProps {
    changes: RecordChange[]
}

export const RecordHistory = (props: HistoryProps) => {
    const { changes } = props

    return (
        <div>
            <h3 className='mb-3 uppercase'>History</h3>
            {changes
                .sort((a, b) => {
                    if (b.created_at.isAfter(a.created_at)) {
                        return 1
                    }

                    if (a.created_at.isAfter(b.created_at)) {
                        return -1
                    }

                    return 0
                })
                .map(change => (
                    <div
                        className='card mb-3'
                        key={`change_card_${change.id}_${change.oldValue}`}>
                        <div className='card-header'>
                            <strong className='me-4'>
                                {change.createdBy}
                            </strong>
                            {change.created_at_local.format('MMM D, YYYY [at] h:mm A')}
                        </div>
                        <div className='card-body'>
                            {change.oldValue !== change.newValue && (
                                <p>Status: {change.oldValue} → {change.newValue}</p>
                            )}
                            {change.oldSubstatus !== change.newSubstatus && (
                                <p>Substatus: {change.oldSubstatus ?? 'None'} → {change.newSubstatus ?? 'None'}</p>
                            )}                      </div>
                    </div>
                ))}
        </div>
    )
}
