import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { JsonAgency, Agency } from '../../models'
import { getAgencies } from '../../api'
import { getAuthUser } from '../../helpers'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions, Loading } from '../common'

export const AgenciesUnknownSupplierLanding = () => {
    const [agencies, setAgencies] = useState<Agency[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        setIsLoading(true)

        getAgencies({ unknownSupplier: true })
            .then(json => {
                setAgencies(json.data.map((agency: JsonAgency) => new Agency(agency)))
                setIsLoading(false)
            })
    }, [])

    if (!ability.can('View', 'Agency')) {
        return <InsufficientPermissions />
    }

    return (
        <div className='container'>
            <h3>Agencies - Unknown Supplier</h3>
            <table className='datatable table'>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>EIN</th>
                        <th>Street</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Country</th>
                        <th>Records</th>
                        <th>Unknown Supplier</th>
                    </tr>
                    {isLoading && <Loading />}
                    {agencies.map(agency =>
                        <tr key={agency.id}>
                            <td>
                                <Link to={`/agencies/${agency.id}`}>
                                    {agency.name}
                                </Link>
                            </td>
                            <td>{agency.ein}</td>
                            <td>{agency.physicalAddress.street}</td>
                            <td>{agency.physicalAddress.city}</td>
                            <td>{agency.physicalAddress?.state?.name}</td>
                            <td>{agency.physicalAddress.zip}</td>
                            <td>{agency.physicalAddress.country?.name}</td>
                            <td>{agency.recordCount}</td>
                            <td>
                                <Link to={`/records/unknownSupplier?agencyId=${agency.id}`}>
                                    {agency.unknownSupplierRecordCount}
                                </Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
