import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSupplierStatuses, getUser, getUsers } from '../../../api'
import {
    JsonSupplierStatus,
    JsonUser,
    SupplierStatus,
    SupplierSubstatus,
    User,
} from '../../../models'
import { getAuthUser, Roles, userHasRole } from '../../../helpers'
import defineAbilityFor from '../defineAbility'

interface FilterProps {
    updateFilters: (filterName: string, newValue: string, shouldDelete?: boolean) => void
}

export const SupplierFilters = (props: FilterProps) => {
    const { updateFilters } = props

    const ability = defineAbilityFor(getAuthUser())
    const currentUser = getAuthUser().id.toString()

    const [currentUserData, setCurrentUserData] = useState<User>()
    const [collectionAgentUsers, setCollectionAgentUsers] = useState<User[]>([])
    const [collectionsStatuses, setCollectionsStatuses] = useState<SupplierStatus[]>([])
    const [collectionsSubstatuses, setCollectionsSubstatuses] = useState<SupplierSubstatus[]>([])

    useEffect(() => {
        Promise.allSettled([
            getUser(currentUser).then((json) => {
                if (json.data) {
                    setCurrentUserData(json.data)
                }
            }),
            getUsers().then((json) => {
                const collectionAgentUsers = json.data
                    .map((user: JsonUser) => new User(user))
                    .filter((user: User) => userHasRole(user, Roles.CollectionAgent))
                setCollectionAgentUsers(collectionAgentUsers)
            }),
            getSupplierStatuses().then(json => {
                const supplierStatuses = json.data
                    .map((status: JsonSupplierStatus) => new SupplierStatus(status))
                setCollectionsStatuses(supplierStatuses)
                const collectionsStatus = supplierStatuses
                    .find((status: SupplierStatus) => status.code === SupplierStatus.COLLECTIONS)
                if (collectionsStatus) {
                    setCollectionsSubstatuses(collectionsStatus.substatuses || [])
                }
            })
        ])
            .then(results => {
                results.forEach((result) => {
                    if (result.status === 'rejected') {
                        toast.error(result.reason.message);
                    }
                })
            })
    }, [currentUser])

    return (
        <div className="row">
            <div className="col-md-2">
                <label htmlFor='assignedUserFilter'>Assigned to:</label>
                <select
                    className='form-select'
                    id='assignedUserFilter'
                    onChange={(e) => {
                        const selectedUserId = e.target.value ?? ''
                        updateFilters('assigned_user_id', selectedUserId, selectedUserId === '-1')
                    }}
                >
                    <option key={-1} value={-1}>All</option>
                    {ability.can('Filter', 'SupplierCollections')
                        ? collectionAgentUsers.map((user: User) => (
                            <option key={user.id} value={user.id}>{user.name}</option>)
                        ) : currentUserData && (
                            <option value={currentUserData.id}>{currentUserData.name}</option>
                    )}
                </select>
            </div>
            <div className='col-md-5'>
                <label htmlFor="supplierStatusFilter">Status:</label>
                <select
                    className='form-select'
                    id='supplierStatusFilter'
                    onChange={(e) => {
                        updateFilters(
                            'status',
                            e.target.value ?? '',
                            e.target.value === '-1',
                        )
                    }}
                >
                    <option value={-1}>All</option>
                    {collectionsStatuses.map((supplierStatus: SupplierStatus) => (
                        <option key={supplierStatus.id} value={supplierStatus.code}>
                            {supplierStatus.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-md-5">
                <label htmlFor='supplierSubstatusFilter'>Substatus</label>
                <select
                    className='form-select'
                    id='supplierSubstatusFilter'
                    onChange={(e) => {
                        const selectedSubstatus = e.target.value ?? ''
                        updateFilters(
                            'supplier_substatus_code',
                            selectedSubstatus,
                            selectedSubstatus === '-1'
                        )
                    }}>
                    <option value={-1}>All</option>
                    {collectionsSubstatuses.map((substatus: SupplierSubstatus) => (
                        <option key={substatus.code} value={substatus.code}>
                            {substatus.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
