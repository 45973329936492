import React from 'react'

import { Chain } from '../../models'

interface ChainTableProps {
    chain: Chain
}

export const ChainTable = (props: ChainTableProps) => {
    const { chain } = props

    return (
        <table className='table datatable'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <td>{chain.name}</td>
                </tr>
                <tr>
                    <th>Chain Code</th>
                    <td>{chain.abbreviation}</td>
                </tr>
                <tr>
                    <th>Code</th>
                    <td>{chain.code}</td>
                </tr>
            </tbody>
        </table>
    )
}
