import React, { useState } from 'react'

import { TooltipWrapper } from '../../common'
import { getAuthUser } from '../../../helpers'
import { DeleteAgencyEmail } from './delete'
import { AgencyEmailForm } from './form'
import { AgencyEmailTable } from './table'
import defineAbilityFor from './defineAbility'

interface CardProps {
    onEmailsChanged: (
        emails: string[],
        primaryEmail: string,
        successCallback?: () => void
    ) => void

    emails: string[]
    primaryEmail: string
}

export const AgencyEmailsCard = (props: CardProps) => {
    const { emails, onEmailsChanged, primaryEmail } = props

    const [isAdding, setIsAdding] = useState(false)
    const [emailToDelete, setEmailToDelete] = useState('')
    const [emailToEdit, setEmailToEdit] = useState('')

    const ability = defineAbilityFor(getAuthUser())

    const handleAdd = (newEmail: string) => {
        const updated = [
            ...emails,
            newEmail
        ]
        onEmailsChanged(
            updated,
            primaryEmail || newEmail,
            () => setIsAdding(false)
        )
    }

    const handleDelete = (deletedEmail: string) => {
        const updated = emails.filter((email) => email !== deletedEmail)

        onEmailsChanged(updated, primaryEmail, () => setEmailToDelete(''))
    }

    const handleEdit = (oldEmail: string, updatedEmail: string) => {
        const updated = emails.map((email) => {
            return email === oldEmail ? updatedEmail : email
        })

        onEmailsChanged(
            updated,
            oldEmail === primaryEmail ? updatedEmail : primaryEmail,
            () => setEmailToEdit('')
        )
    }

    return (
        <div className='container card mb-3'>
            <div className='
                card-body
                col-xs-12
                row
                d-flex
                flex-row
                justify-content-between
            '>
                <div className='row flex-row mb-5 pe-0'>
                    <h3 className='ps-0 d-inline-flex card-title w-50 uppercase'>
                        Email
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                        {!isAdding && !emailToDelete && !emailToEdit &&
                            <TooltipWrapper
                                condition={!ability.can('Create', 'AgencyEmail')}
                                element={
                                    <button
                                        className='btn btn-primary'
                                        disabled={!ability.can('Create', 'AgencyEmail')}
                                        onClick={() => setIsAdding(true)}>
                                        Add New Email
                                    </button>
                                }
                                message='Insufficient permissions' />
                        }
                        {isAdding &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setIsAdding(false)}>
                                Cancel
                            </button>
                        }
                        {emailToDelete &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setEmailToDelete('')}>
                                Cancel
                            </button>
                        }
                        {emailToEdit &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setEmailToEdit('')}>
                                Cancel
                            </button>
                        }
                    </div>
                </div>
                <div className='row flex-row p-0'>
                    {!isAdding && !emailToDelete && !emailToEdit &&
                        <AgencyEmailTable
                            onEmailsChanged={onEmailsChanged}
                            emails={emails}
                            primaryEmail={primaryEmail}
                            onEmailToDeleteChanged={setEmailToDelete}
                            onEmailToEditChanged={setEmailToEdit} />
                    }
                    {isAdding &&
                        <AgencyEmailForm
                            key='new_email'
                            handleSubmit={(email: string) => handleAdd(email)}
                            title='Add Email' />
                    }
                    {emailToDelete &&
                        <DeleteAgencyEmail
                            email={emailToDelete}
                            onDelete={() => handleDelete(emailToDelete)} />
                    }
                    {emailToEdit &&
                        <AgencyEmailForm
                            key={emailToEdit}
                            email={emailToEdit}
                            handleSubmit={(email: string) => handleEdit(emailToEdit, email)}
                            title='Edit Email' />
                    }
                </div>
            </div>
        </div>
    )
}
