import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { getAuthUser } from '../../helpers'
import { Loading, TooltipWrapper } from '../common'
import { Supplier, VendorCode } from '../../models'
import { getSupplier, getVendorCode } from '../../api'
import { EditSupplierForm } from './edit'
import { SupplierTable } from './resourceTable'
import defineAbilityFor from './defineAbility'
import { DeleteSupplier } from './delete'

const BalanceRow = styled.div``
const Buttons = styled.div``
const TitleRow = styled.div``

interface SupplierCardProps {
    setSupplier: Dispatch<SetStateAction<Supplier | undefined>>
    supplier: Supplier
    onNoteChange: () => void
}

export const SupplierCard = (props: SupplierCardProps) => {
    const { setSupplier, supplier } = props
    const [vendorCode, setVendorCode] = useState<VendorCode | undefined>()
    const [isEditing, setIsEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        if (supplier.id) {
            getSupplier(supplier.id.toString())
                .then(response => {
                    if (response.status === 200) {
                        setSupplier(new Supplier(response.data))
                    }
                })
        }
    }, [supplier.id])

    useEffect(() => {
        if (supplier?.vendorId != undefined) {
            getVendorCode(supplier?.vendorId.toString())
                .then(response => {
                    if (response.status === 200) {
                        setVendorCode(new VendorCode(response.data))
                    }
                })
        } else {
            setVendorCode(undefined)
        }
    }, [supplier?.vendorId])

    useEffect(() => {
        if (supplier?.vendorId != undefined) {
            getVendorCode(supplier?.vendorId.toString())
                .then(response => {
                    if (response.status === 200) {
                        setVendorCode(new VendorCode(response.data))
                    }
                })
        } else {
            setVendorCode(undefined)
        }
    }, [supplier?.vendorId])

    if (!(supplier instanceof Supplier) || ((supplier?.vendorId != undefined) && !(vendorCode instanceof VendorCode))) {
        return <Loading />
    }

    if (isDeleting) {
        return (
            <DeleteSupplier
                setIsDeleting={setIsDeleting}
                supplier={supplier} />
        )
    }

    return (
        <div className='container card mb-3'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <TitleRow className='row flex-row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div>
                                <h3 className='ps-0 card-title d-block uppercase'>
                                    {supplier.name || 'NO NAME'}
                                </h3>
                                {supplier.dba && (
                                    <h5 className='ps-0 card-subtitle d-block'>
                                        Doing Business as {supplier.dba.toUpperCase()}
                                    </h5>
                                )}
                            </div>
                            <Buttons className='d-inline-flex justify-content-end align-items-center'>
                                <TooltipWrapper
                                    condition={!ability.can('Delete', 'Supplier')}
                                    element={
                                        <button
                                            className='btn btn-danger me-2'
                                            style={{ height: '40px' }}
                                            disabled={!ability.can('Delete', 'Supplier')}
                                            onClick={() => setIsDeleting(true)}>
                                            Delete
                                        </button>
                                    }
                                    message='Executive permission required to delete supplier' />
                                <TooltipWrapper
                                    condition={!ability.can('Edit', 'Supplier')}
                                    element={
                                        <button
                                            className='btn btn-primary'
                                            style={{ height: '40px' }}
                                            disabled={!ability.can('Edit', 'Supplier')}
                                            onClick={() => setIsEditing(!isEditing)}>
                                            Edit
                                        </button>
                                    }
                                    message='Contributor permission required to edit supplier' />
                            </Buttons>
                        </div>
                    </div>
                </TitleRow>
                <hr />
                <BalanceRow className='row'>
                    <div className='col'>
                        <h4 className='ps-0'>
                            Balance: {supplier.balance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}
                        </h4>
                        <h5 className='ps-0'>
                            No. of Records:
                            <Link
                                to={`/records?supplierId=${supplier.id}`}
                                className='clickable text-primary'>
                                {supplier.recordCount}
                            </Link>
                        </h5>
                        <h5 className='ps-0'>
                            <Link
                                to={`/invoices?supplierId=${supplier.id}`}
                                className='clickable text-primary'>
                                View Invoices
                            </Link>
                        </h5>
                    </div>
                </BalanceRow>
                <div className='col'>
                    {!isEditing && <SupplierTable supplier={supplier} vendorCode={vendorCode} />}
                    {isEditing && (
                        <EditSupplierForm
                            supplier={supplier}
                            setIsEditing={setIsEditing}
                            setSupplier={setSupplier} />
                    )}
                </div>
            </div>
        </div>
    )
}
