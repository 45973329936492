import React, { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'

import {
    updateSupplierAddresses,
    UpdateSupplierAddressesParams,
    syncSupplierAddresses
} from '../../../api'
import { getAuthUser } from '../../../helpers'
import { Address, JsonAddress } from '../../../models'
import { FormattedAddress, TooltipWrapper } from '../../common'
import defineAbilityFor from './defineAbility'

interface TableProps {
    addresses: Address[]
    setAddresses: Dispatch<SetStateAction<Address[]>>
    setAddressToEdit: Dispatch<SetStateAction<Address | undefined>>
    supplierId: number
}

export const SupplierAddressTable = (props: TableProps) => {
    const { addresses, setAddresses, setAddressToEdit, supplierId } = props

    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (params: UpdateSupplierAddressesParams, message: string) => {
        updateSupplierAddresses(supplierId.toString(), params)
            .then((response) => {
                if (response.status === 200) {
                    setAddresses(response.data.map(
                        (address: JsonAddress) => new Address(address)
                    ))

                    toast.success(message)
                }
            })
            .catch((error) => {
                toast.error(error.response.data , {
                    autoClose: false
                })
            })
    }

    const handleDelete = (addressId: number) => {
        const params = {
            addressIdToDetach: addressId
        }

        syncSupplierAddresses(supplierId.toString(), params)
            .then((response) => {
                setAddresses(response.data.addresses.map(
                    (address: JsonAddress) => new Address(address)
                ))

                toast.success('Address Deleted')
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <table className='datatable table'>
            <tbody>
            {[...addresses]
                .sort((a, b) => +b.isBilling - +a.isBilling)
                .sort((a, b) => +b.isPhysical - +a.isPhysical)
                .map(address => (
                    <tr className='d-flex' key={`supplier_address_${address.id}`}>
                        <td style={{ width: '45%' }}>
                            <FormattedAddress
                                city={address.city}
                                country={address.country?.name ?? ''}
                                state={address?.state?.name ?? ''}
                                street={address.street}
                                street2={address.street2}
                                zip={address.zip} />
                        </td>
                        <td className='d-flex align-items-end flex-column ps-0 pe-0'
                            style={{ width: '55%' }}>
                            <span className='d-flex'>
                            {address.isValid &&
                                <span className='badge bg-primary rounded-pill ms-2'>
                                    Validated
                                </span>
                            }
                            {address.isInvalid &&
                                <span className='badge bg-danger rounded-pill ms-2'>
                                    Invalid
                                </span>
                            }
                            {address.isUnvalidated &&
                                <span className='badge bg-warning rounded-pill ms-2'>
                                    Unvalidated
                                </span>
                            }
                            {address.isBilling &&
                                <span className='badge bg-dark rounded-pill ms-2'>
                                    Billing
                                </span>
                            }
                            {address.isPhysical &&
                                <span className='badge bg-success rounded-pill ms-2'>
                                    Physical
                                </span>
                            }
                            </span>
                            <span className='d-flex mb-2 mt-auto align-items-end'>
                                <form onSubmit={(e) => {
                                    e.preventDefault()

                                    handleSubmit(
                                        { billingAddressId: address.id },
                                        'Billing Address Updated'
                                    )
                                }}>
                                    <TooltipWrapper
                                        condition={!address.isBillable
                                            || address.isBilling
                                            || !ability.can('Update', 'SupplierAddress')
                                        }
                                        element={
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                disabled={!address.isBillable
                                                    || address.isBilling
                                                    || !ability.can('Update', 'SupplierAddress')
                                                }
                                                type='submit'>
                                                Set as Billing
                                            </button>
                                        }
                                        message={address.isBilling
                                            ? 'Address is currently billing'
                                            : !address.isBillable
                                                ? 'Address needs validation'
                                                : 'Insufficient permissions'
                                        } />
                                </form>
                                <form onSubmit={(e) => {
                                    e.preventDefault()

                                    handleSubmit(
                                        { physicalAddressId: address.id },
                                        'Physical Address Updated'
                                    )
                                }}>
                                    <TooltipWrapper
                                        condition={address.isPhysical
                                            || !ability.can('Update', 'SupplierAddress')
                                        }
                                        element={
                                            <button
                                                className='btn btn-outline-primary btn-sm ms-1'
                                                disabled={address.isPhysical
                                                    || !ability.can('Update', 'SupplierAddress')
                                                }
                                                type='submit'>
                                                Set as Physical
                                            </button>
                                        }
                                        message={address.isPhysical
                                            ? 'Address is currently physical'
                                            : 'Insufficient permissions'
                                        } />
                                </form>
                                <form onSubmit={(e) => {
                                    e.preventDefault()

                                    handleDelete(address.id)
                                }}>
                                    <TooltipWrapper
                                        condition={address.isPhysical
                                            || address.isBilling
                                            || !ability.can('Delete', 'SupplierAddress')
                                        }
                                        element={
                                            <button
                                                className='btn btn-outline-danger btn-sm ms-1'
                                                disabled={address.isPhysical
                                                    || address.isBilling
                                                    || !ability.can('Delete', 'SupplierAddress')
                                                }
                                                type='submit'>
                                                Delete
                                            </button>
                                        }
                                        message={(address.isPhysical || address.isBilling)
                                            ? 'Cannot delete billing or physical address'
                                            : 'Insufficient permissions'
                                        } />
                                </form>
                                <TooltipWrapper
                                    condition={!ability.can('Update', 'SupplierAddress')}
                                    element={
                                        <button
                                            className='btn btn-primary btn-sm mt-auto ms-1'
                                            disabled={
                                                !ability.can('Update', 'SupplierAddress')
                                            }
                                            onClick={() => setAddressToEdit(address)}>
                                            Edit
                                        </button>
                                    }
                                    message='Insufficient permissions' />
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
