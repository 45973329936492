import React, { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { updateSupplierAssignedUser } from '../../../api'
import { User } from '../../../models'
import { QueryParams } from '../../common/paginatedTable'
import { Loading } from '../../common'

interface AssignRecordsFormProps {
    queryParams: QueryParams
    getSupplierCount: (queryParams: QueryParams) => Promise<number>
    users: User[]
    selectedSuppliers: Number[]
    setSelectedSuppliers: (selectedSuppliers: Number[]) => void
}

export const AssignSuppliersForm = (props: AssignRecordsFormProps) => {
    const { queryParams, getSupplierCount, users } = props
    const [assignedUserId, setAssignedUserId] = useState<string>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isFetchingSupplierCount, setIsFetchingSupplierCount] = useState(false)
    const [supplierCount, setSupplierCount] = useState<number | null>(null)

    const navigate = useNavigate()

    useEffect(() => {
        let isCancelled = false

        setIsFetchingSupplierCount(true)
        getSupplierCount(queryParams).then((response: any) => {
            if (!isCancelled) {
                setIsFetchingSupplierCount(false)
                setSupplierCount(response.data.count)
            }
        })
        return () => {
            isCancelled = true
        }
    }, [queryParams, getSupplierCount])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (assignedUserId) {
            const params = {
                assigned_user_id: assignedUserId,
                selected_suppliers: props.selectedSuppliers,
                search: queryParams?.search,
            }
            setIsSubmitting(true)

            updateSupplierAssignedUser(params)
                .then(() => {
                    setIsSubmitting(false)
                    setIsFetchingSupplierCount(true)
                    getSupplierCount(queryParams).then((response: any) => {
                        setIsFetchingSupplierCount(false)
                        setSupplierCount(response.data.count)
                        props.setSelectedSuppliers([])
                    })
                    toast.success('Suppliers Assigned')
                    navigate(0)
                })
                .catch((error) => toast.error(error, { autoClose: false }))
        }
    }

    const getSupplierCountToAssign = () => {
        return props.selectedSuppliers.length > 0 ? props.selectedSuppliers.length : 200
    }

    // Count how many suppliers are assigned to the user selected
    const assignedSuppliersCount = props.users.find(
        (user) => user.id === Number(assignedUserId)
    )?.assignedSupplierCount

    // Count the maximum number of supplier to assign to the user selected based on how many suppliers are already assigned to them
    const maxSupplierToAssign = assignedSuppliersCount
        ? 200 - assignedSuppliersCount - getSupplierCountToAssign()
        : 200 - getSupplierCountToAssign()

    const alertMessage = () => {
        if (maxSupplierToAssign >= 0) {
            return `${maxSupplierToAssign} suppliers left can be assigned to this user.`;
        } else {
            return "Number of suppliers to assign is too many!";
        }
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="d-flex">
            <div style={{ marginRight: '20px' }}>
                <label htmlFor='usersInput'>
                    Assign Suppliers to User
                </label>
                <select
                    className='form-select mb-2'
                    id='usersInput'
                    onChange={(e) => setAssignedUserId(e.target.value)}
                    required>
                    <option value=''>
                        Select User
                    </option>
                    {users.map(user => (
                        <option key={`user_option_${user.id}`} value={user.id}>
                            {user.name}
                        </option>
                    ))}
                </select>
                {assignedUserId && (
                    <h6 className='text-danger' role='alert'>
                        *{alertMessage()}
                    </h6>
                )}
            </div>
            <div>
                <button
                    className='btn btn-primary'
                    style={{ marginTop: '22px' }}
                    disabled={
                        isSubmitting ||
                        assignedUserId === undefined ||
                        maxSupplierToAssign < 0
                    }
                    type='submit'>
                    Assign {getSupplierCountToAssign()} of {isFetchingSupplierCount ? <Loading sm={true} /> : supplierCount} unassigned {isSubmitting && <Loading sm={true} />}
                </button>
            </div>
        </form>
    )
}
