import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Record } from '../../../models'
import { updateRecord } from '../../../api'
import { PaginatedTableContract } from '../../common'
import { getForeignPaidCt } from '../../../helpers'

interface UnknownSupplierProps extends PaginatedTableContract {
    resources: {
        records: Record[]
    }
}

export const ForeignConversionTable = (props: UnknownSupplierProps) => {
    const { resources, callbacks } = props
    const { records } = resources
    const { setReload } = callbacks

    interface ExhangeRateInputs {
        [id: string]: string
    }

    interface PaidCtRows {
        [id: string]: number
    }

    const [exchangeRateInputs, setExchangeRateInputs] = useState<ExhangeRateInputs>({})
    const [paidCtRows, setPaidCtRows] = useState<PaidCtRows>({})

    useEffect(() => {
        let inputs: {[id: number]: string} = {}

        records.forEach(record => {
            inputs[record.id] = ''
        })

        setExchangeRateInputs(inputs)

        let rows: PaidCtRows = {}

        records.forEach(record => {
            rows[record.id] = record.paidCt
        })

        setPaidCtRows(rows)
    }, [records])

    const handleChange = (record: Record, rate: string) => {
        if (+rate >= 0 && +rate < 101) {
            const inputs = {
                ...exchangeRateInputs,
                [record.id]: rate
            }

            setExchangeRateInputs(inputs)

            if (!isNaN(+rate)) {
                const rows = {
                    ...paidCtRows,
                    [record.id]: getForeignPaidCt(record.paidCt, +rate)
                }

                setPaidCtRows(rows)
            }
        }
    }

    const handleSubmit = (record: Record) => {
        const paid_ct = +paidCtRows[record.id]

        if (paid_ct && paid_ct !== record.paidCt) {
            updateRecord(record.id.toString(), { paid_ct })
                .then(response => {
                    if (response.status === 200 && setReload) {
                        toast.success(`${record.id} Updated`)
                        setReload(true)
                    }
                }).catch(error => {
                    const { response } = error
                    const { message } = response.data

                    if (message) {
                        toast.error(message, { autoClose: false })
                    } else {
                        toast.error(response.data, { autoClose: false })
                    }
                })
        }
    }

    return (
        <table className='datatable table'>
            <tbody>
                <tr>
                    <th>REC</th>
                    <th>Phone</th>
                    <th>Supplier Name</th>
                    <th>Foreign Value</th>
                    <th>Exchange Rate</th>
                    <th>Paid CT</th>
                    <th colSpan={2} />
                </tr>
                {records.map(record =>
                    <tr key={record.id}>
                        <td><Link to={`/records/${record.id}`}>{record.id}</Link></td>
                        <td>{record.phone}</td>
                        <td>{record.supplierName}</td>
                        <td>{record.forval}</td>
                        <td>
                            <input
                                className='form-control'
                                min={0}
                                max={100}
                                step={0.01}
                                onChange={(e) => handleChange(record, e.target.value)}
                                type='number'
                                required
                                value={exchangeRateInputs[record.id] ?? ''} />
                        </td>
                        <td>{paidCtRows[record.id]}</td>
                        <td>
                            <button
                                className='btn btn-primary'
                                disabled={+paidCtRows[record.id] <= 0
                                    || +paidCtRows[record.id] === record.paidCt
                                }
                                onClick={() => {
                                    if (exchangeRateInputs[record.id] !== '') {
                                        handleSubmit(record)
                                    }
                                }}>
                                Update Paid CT
                            </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
