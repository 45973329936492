import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { uploadSupplierRecording, listSupplierRecordings, getSupplierRecording, deleteSupplierRecording } from '../../api/requests'
import { SupplierRecording } from '../../models/supplierRecording'
import { User } from 'models'
import { Loading } from 'components/common'
import defineAbility from './defineAbility';
import { getAuthUser } from '../../helpers'

interface Props {
    supplierId: number
    users: User[]
}

export const RecordingManager = (props: Props) => {
    const [mp3Files, setMp3Files] = useState<SupplierRecording[]>([])
    const [file, setFile] = useState<File | null>(null)
    const [description, setDescription] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const users = props.users
    const supplierId = props.supplierId
    const [inputKey, setInputKey] = useState(Date.now())
    const ability = defineAbility(getAuthUser())

    useEffect(() => {
        fetchMp3Files()
    }, [])

    const fetchMp3Files = async () => {
        try {
            const response = await listSupplierRecordings(supplierId)
            if (!Array.isArray(response.data)) {
                throw new Error()
            }
            const recordings = response.data.map((data: any) => new SupplierRecording(data))
            setMp3Files(recordings)
        } catch (error) {
            toast.error('Failed to fetch MP3 files', { autoClose: false })
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0])
        }
    }

    const handleUpload = async () => {
        if (file && !isSubmitting) {
            setIsSubmitting(true)
            const formData = new FormData()
            formData.append('mp3', file)
            formData.append('description', description)

            try {
                await uploadSupplierRecording(supplierId, formData)
                toast.success('MP3 uploaded successfully')
                fetchMp3Files()
            } catch (error) {
                toast.error('Failed to upload MP3 file')
            } finally {
                setFile(null)
                setDescription('')
                setIsSubmitting(false)
                setInputKey(Date.now())
            }
        }
    }

    const handleDownload = async (filename: string) => {
        try {
            const response = await getSupplierRecording(supplierId, filename)
            const reader = new FileReader()
            reader.onload = () => {
                try {
                    const responseData = JSON.parse(reader.result as string)
                    if (responseData.url) {
                        const url = responseData.url
                        const link = document.createElement('a')
                        link.href = url
                        link.download = filename
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        window.URL.revokeObjectURL(url)
                        toast.success('MP3 downloaded successfully')
                    } else {
                        toast.error('No download URL found in the response', { autoClose: false })
                    }
                } catch (error) {
                    toast.error('Error downloading file: Invalid server response', { autoClose: false })
                }
            }
            reader.readAsText(response.data)
        } catch (error) {
            toast.error('Failed to download MP3 file', { autoClose: false })
        }
    }

    const handleDelete = async (filename: string) => {
        if (window.confirm('Are you sure you want to delete this recording?')) {
            try {
                await deleteSupplierRecording(supplierId, filename)
                toast.success('MP3 deleted successfully')
                fetchMp3Files()
            } catch (error) {
                toast.error('Failed to delete MP3 file', { autoClose: false })
            }
        }
    }

    if (!ability.can('View', 'SupplierRecordings')) {
        return null
    }

    return (
        <div className="container card mb-4">
            <div className="card-body">
                <h5 className="card-title uppercase">Upload MP3</h5>
                {isSubmitting ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px'
                    }}>
                        <Loading />
                    </div>
                ) : (
                    <>
                        <div className="mb-3">
                            <input type="file" key={inputKey} className="form-control" accept=".mp3" onChange={handleFileChange} />
                        </div>
                        <div className="mb-3">
                            <textarea
                                className="form-control"
                                placeholder="Enter description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <button className="btn btn-primary mb-4" onClick={handleUpload}>Upload</button>
                    </>
                )}

                <h3 className="card-title uppercase">Available MP3s</h3>
                <ul className="list-group">
                    {mp3Files.map((mp3: SupplierRecording, index: number) => (
                        <li key={index} className="list-group-item position-relative">
                            <div>
                                <p className="mb-1"><strong>Filename:</strong> {mp3.filename}</p>
                                <p className="mb-1"><strong>Uploaded At:</strong> {mp3.created_at.format('YYYY-MM-DD HH:mm:ss')}</p>
                                <p className="mb-1"><strong>Uploaded By:</strong> {users.find(user => user.id === mp3.created_by)?.name}</p>
                                <p className="mb-1"><strong>Description:</strong> {mp3.description}</p>
                            </div>
                            <div className="position-absolute bottom-0 end-0 p-3">
                                <button className="btn btn-secondary me-2" onClick={() => handleDownload(mp3.filename)}>Download</button>
                                {ability.can('Delete', 'SupplierRecording') && (
                                    <button className="btn btn-danger" onClick={() => handleDelete(mp3.filename)}>Delete</button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
