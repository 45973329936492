import React from 'react'

import { CreateInvoiceForm, CreateInvoiceFormProps } from '../invoices/createInvoiceForm';

export const SupplierCreateInvoiceCard = (props: CreateInvoiceFormProps) => {
    return <div className="container card mb-2 pb-4">
            <div
                className="
                card-body
                col-xs-12
                row
                d-flex
                flex-row
                justify-content-between
            "
            >
                <div className="row flex-row mb-2">
                    <h3 className="ps-0 d-inline-flex card-title w-50 uppercase">Create Invoice</h3>
                </div>
            </div>
            <div className="row flex-row">
                <div className='col'>
                    <CreateInvoiceForm {...props} />
                </div>
            </div>
        </div>;
}
