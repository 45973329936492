import React, { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import { AnyMongoAbility } from '@casl/ability';

import { getAuthUser } from '../../helpers'
import { TooltipWrapper } from '../common'
import { Record, RecordStatus } from '../../models'
import { DeleteRecord, EditRecordForm } from './'
import { RecordTable } from './resourceTable'
import defineAbilityFor from './defineAbility'

const Badge = styled.h4`
    padding-left: 0px;
`
const BadgeRow = styled.div``
const TitleRow = styled.div``

interface RecordProps {
    ability: AnyMongoAbility
    record: Record
    setRecord: Dispatch<SetStateAction<Record | undefined>>
}

export const RecordCard = (props: RecordProps) => {
    const { record, setRecord } = props

    const [isEditing, setIsEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const ability = defineAbilityFor(getAuthUser())

    if (isDeleting) {
        return (
            <DeleteRecord
                setIsDeleting={setIsDeleting}
                record={record} />
        )
    }

    const recordType = (() => {
        if (record.status.code === RecordStatus.PAID) {
            return 'PaidRecord'
        } else if (RecordStatus.REVIEW_STATUSES.includes(record.status.code)) {
            return 'NeedsReviewRecord'
        }
        return 'Record'
    })()
    const canEditRecord = ability.can('Edit', recordType)

    return (
        <div className='container card'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <TitleRow className='row flex-row'>
                    <h3 className='ps-0 d-inline-flex card-title w-50 uppercase'>
                        Record {record.id}
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50 align-items-center'>
                        <TooltipWrapper
                            condition={!ability.can('Delete', 'Record')}
                            element={
                                <button
                                    className='btn btn-danger me-3'
                                    disabled={!ability.can('Delete', 'Record')}
                                    onClick={() => setIsDeleting(true)}>
                                    Delete
                                </button>
                            }
                            message='Executive permission required to delete record' />
                        <TooltipWrapper
                            condition={!canEditRecord}
                            element={
                                <button
                                    className='btn btn-primary'
                                    disabled={!canEditRecord}
                                    onClick={() => setIsEditing(!isEditing)}>
                                    Edit
                                </button>
                            }
                            message='You do not have permission to edit this record' />
                    </div>
                </TitleRow>
                <BadgeRow className='row flex-row mb-5'>
                    {!record.supplier &&
                        <Badge>
                            <span className='badge bg-warning'>Missing Supplier</span>
                        </Badge>
                    }
                </BadgeRow>
                {!isEditing && <RecordTable record={record} />}
                {isEditing && (
                    <EditRecordForm
                        record={record}
                        setIsEditing={setIsEditing}
                        setRecord={setRecord} />
                )}
            </div>
        </div>
    )
}
