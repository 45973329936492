import React from 'react'
import { Link } from 'react-router-dom'

import { Country } from '../../models'
import { PaginatedTableContract } from '../common'

interface CountriesTableProps extends PaginatedTableContract {
    resources: {
        countries: Country[]
    }
}

export const CountriesTable = (props: CountriesTableProps) => {
    const { resources } = props
    const { countries } = resources

    return (
        <table className='datatable table'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>ISO Code</th>
                    <th>Billable</th>
                    <th>Currency</th>
                    <th>Exchange Rate</th>
                </tr>
                {countries.map(country =>
                    <tr key={country.id}>
                        <td>
                            <Link to={`/countries/${country.id}`}>
                                {country.name}
                            </Link>
                        </td>
                        <td>{country.abbreviation}</td>
                        <td>{country.billable ? 'Yes' : 'No'}</td>
                        <td>{country.currency}</td>
                        <td>{country.exchange}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
