import React, { FormEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router'
import { SupplierBalance, User } from '../../../models'
import { PaginatedTableContract } from '../../common'
import { currencyFormat } from '../../../helpers/currencyCalculation'
import defineAbilityFor from './../defineAbility'
import { getAuthUser } from '../../../helpers'
import { getUser, updateSupplierAssignedUser } from '../../../api'
import { toast } from 'react-toastify'

interface SupplierCollectionsTableProps extends PaginatedTableContract {
    setSelectedSuppliers: (selectedSuppliers: number[]) => void
    resources: {
        supplierBalances: SupplierBalance[]
    }
    users: User[]
    selectedSuppliers: number[]
    toggleSupplierSelection: (id: number) => void
}

export const SupplierCollectionsTable = (props: SupplierCollectionsTableProps) => {
    const ability = defineAbilityFor(getAuthUser())
    const { resources, selectedSuppliers, toggleSupplierSelection, users } = props
    const { supplierBalances } = resources
    const [assignedUserNames, setAssignedUserNames] = useState<{
        [key: number]: string | null
    }>({})
    const [showAssignForm, setShowAssignForm] = useState<boolean>(false)
    const [currentSupplierId, setCurrentSupplierId] = useState<number | null>(null)
    const [assignedUserId, setAssignedUserId] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const navigate = useNavigate()

    useEffect(() => {
        const fetchAssignedUserNames = async () => {
            const userIds = supplierBalances
                .filter((supplierBalance) => supplierBalance.assignedUser)
                .map((supplierBalance) => supplierBalance.assignedUser)
            const uniqueUserIds = Array.from(new Set(userIds))

            try {
                const userPromises = uniqueUserIds.map((id) => getUser(id.toString()))
                const userResponses = await Promise.all(userPromises)

                const userData = userResponses.reduce((acc, result) => {
                    if (result.data) {
                        acc[result.data.id] = result.data.name
                    }
                    return acc
                }, {} as { [key: number]: string })

                const updatedAssignedUserNames = supplierBalances.reduce(
                    (acc, supplierBalance) => {
                        if (supplierBalance.assignedUser) {
                            acc[supplierBalance.supplierId] =
                                userData[supplierBalance.assignedUser] || null
                        }
                        return acc
                    },
                    {} as { [key: number]: string | null }
                )

                setAssignedUserNames(updatedAssignedUserNames)
            } catch (error) {
                console.error('Error fetching user data:', error)
            }
        }

        fetchAssignedUserNames()
    }, [supplierBalances])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (assignedUserId) {
            const params = {
                assigned_user_id: assignedUserId,
                selected_suppliers: props.selectedSuppliers,
                reassign: true,
            }
            setIsSubmitting(true)

            updateSupplierAssignedUser(params)
                .then(() => {
                    setIsSubmitting(false)
                    navigate(0)
                    toast.success('Suppliers Assigned')
                })
                .catch((error) => {
                    setIsSubmitting(false)
                    toast.error(
                        'This user already reached maximum of 100 suppliers assigned. Please choose another user.',
                        { autoClose: false }
                    )
                })
        }
    }

    const handleReassignClick = (supplierId: number) => {
        setShowAssignForm(true)
        setCurrentSupplierId(supplierId)
        props.setSelectedSuppliers([supplierId])
    }

    const handleUnassignClick = (supplierId: number) => {
        props.setSelectedSuppliers([supplierId])

        const params = {
            assigned_user_id: '',
            selected_suppliers: [supplierId],
        }

        updateSupplierAssignedUser(params)
            .then(() => {
                navigate(0)
                toast.success('Supplier unassigned')
            })
            .catch((error) => {
                setIsSubmitting(false)
                toast.error(error.message, { autoClose: false })
            })
    }

    const assignedSuppliersCount = props.users.find(
        (user) => user.id === Number(assignedUserId)
    )?.assignedSupplierCount
    const maximumLimit = assignedSuppliersCount ? assignedSuppliersCount >= 100 : undefined

    return (
        <table className='datatable table'>
            <tbody>
                <tr>
                    {ability.can('AssignToUser', 'Supplier') ? <th></th> : null}
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Balance</th>
                    <th>Last Updated</th>
                    {ability.can('AssignToUser', 'Supplier') && (
                        <>
                            <th>Assigned User</th>
                            {!showAssignForm && <th>Action</th>}
                        </>
                    )}
                </tr>
                {supplierBalances.map((supplierBalance) => (
                    <tr key={supplierBalance.id}>
                        {ability.can('AssignToUser', 'Supplier') ? (
                            <td>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    disabled={supplierBalance.assignedUser != null}
                                    checked={
                                        selectedSuppliers.includes(
                                            supplierBalance.supplierId
                                        ) || supplierBalance.assignedUser != null
                                    }
                                    onChange={() =>
                                        toggleSupplierSelection(supplierBalance.supplierId)
                                    }
                                />
                            </td>
                        ) : null}
                        <td>
                            <Link to={`/suppliers/${supplierBalance.supplierId}`}>
                                {supplierBalance.supplierName}
                            </Link>
                        </td>
                        <td>{supplierBalance.supplierPhone}</td>
                        <td>{currencyFormat(supplierBalance.balance)}</td>
                        <td>{supplierBalance.updated_at.format('MMM DD, YYYY')}</td>
                        {ability.can('AssignToUser', 'Supplier') && (
                            <td>
                                {showAssignForm &&
                                currentSupplierId === supplierBalance.supplierId ? (
                                    <form
                                        onSubmit={handleSubmit}
                                        className="d-flex align-items-center"
                                    >
                                        <div style={{ marginRight: '10px' }}>
                                            <select
                                                className="form-select mb-2"
                                                id="usersInput"
                                                value={assignedUserId}
                                                onChange={(e) =>
                                                    setAssignedUserId(e.target.value)
                                                }
                                                required
                                            >
                                                <option value="">Select User</option>
                                                {users.map((user) => (
                                                    <option
                                                        key={`user_option_${user.id}`}
                                                        value={user.id}
                                                    >
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            style={{ marginRight: '10px' }}
                                            disabled={isSubmitting}
                                        >
                                            Assign
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setShowAssignForm(false)
                                                props.setSelectedSuppliers([])
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </form>
                                ) : (
                                    <span>
                                        {assignedUserNames[supplierBalance.supplierId] ||
                                            'Unassigned'}
                                    </span>
                                )}
                            </td>
                        )}
                        {ability.can('AssignToUser', 'Supplier') && (
                            <td>
                                {!showAssignForm && (
                                    <>
                                        <button
                                            className="btn btn-warning"
                                            style={{ marginRight: '10px' }}
                                            onClick={() => handleReassignClick(supplierBalance.supplierId)}
                                            disabled={
                                                supplierBalance.assignedUser == null ||
                                                maximumLimit
                                            }
                                        >
                                            Reassign
                                        </button>
                                        <button
                                            className="btn btn-danger"
                                            disabled={supplierBalance.assignedUser == null}
                                            onClick={() => handleUnassignClick(supplierBalance.supplierId)}
                                        >
                                            Unassign
                                        </button>
                                    </>
                                )}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
