import React, { useEffect, useState } from 'react'
import { generateReports, getReports, publishReports } from "../../api"
import { Report } from "../../models/report"
import { defineAbilityFor, ReportsTable } from "./"
import { getAuthUser } from "../../helpers"
import { InsufficientPermissions, TooltipWrapper } from "../common"
import { toast } from "react-toastify"
import easternTimezonedDayjs from '../../helpers/dayjsEastern'

export const ReportsLanding = () => {
    const [reports, setReports] = useState<Report[]>([])
    const [publishing, setPublishing] = useState<boolean>(false)
    const [creatingReports, setCreatingReports] = useState<boolean>(false)

    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('view', 'Reports')) {
        return <InsufficientPermissions />
    }

    const oneMonthAgo = easternTimezonedDayjs().subtract(1, 'month')
    const month = oneMonthAgo.month() + 1 // zero-based
    const year = oneMonthAgo.year()
    const longFormattedMonth = oneMonthAgo.format('MMMM')

    const refreshReports = async () => {
        try {
            const {data, status} = await getReports({year, month})
            if (status === 200) {
                setReports(data)
            }
        } catch (e: any) {
            toast.error(e.message, { autoClose: false })
        }
    }

    useEffect(() => {
            (async () => refreshReports())()
    }, [])

    useEffect(() => {
        let f: ReturnType<typeof setInterval>

        if (!!reports.length && !reportsCompleted) {
            const countComplete = reports
                .reduce((acc, report) => {
                    return report.completed
                        ? acc + 1
                        : acc
                }, 0)
            const percentageComplete = countComplete / reports.length
            const minPollDelay = 5_000 // 5 seconds
            const maxSlowdown = 25_000 // 25 seconds
            const slowdown = Math.floor(maxSlowdown * percentageComplete)
            f = setInterval(refreshReports, minPollDelay + slowdown)
        }

        return () => clearInterval(f)
    }, [reports])

    const handleRunReports = async () => {
        setCreatingReports(true)
        try {
            const {data, status} = await generateReports(year, month)
            if (status === 200) {
                setReports(data)
            }
        } catch (e: any) {
            toast.error(e.message, { autoClose: false })
        }
        setCreatingReports(false)
    }

    const handlePublish = async () => {
        setPublishing(true)
        try {
            await publishReports(year, month)
        } catch (e: any) {
            toast.error(e.message, { autoClose: false })
        }
        await refreshReports()
        setPublishing(false)
    }

    const reportsCompleted = reports.every((report: Report) => report.completed)

    const reportsPublished = reports.every((report: Report) => report.published)

    return (
        <div className="container card">
            <div className="card-body">
                <h3 className='uppercase'>Agency Reconciliation Reports</h3>

                {!reportsCompleted &&
                    <LoadingButton message='Processing...' />}

                {!!reports.length && reportsCompleted &&
                    ( publishing
                        ? <LoadingButton message='Publishing...' />
                        : <TooltipWrapper
                              condition={!ability.can('publish', 'Reports')}
                              message="You don't have the required permissions to publish reports."
                              element={
                                  <button
                                      onClick={handlePublish}
                                      disabled={!ability.can('publish', 'Reports') || reportsPublished}
                                      className="btn btn-primary">
                                      {reportsPublished ? 'Published!' : 'Publish'}
                                  </button>
                              } />
                    )
                }

                <hr />
                <h4> {longFormattedMonth} {year}</h4>

                {!!reports.length
                    ? <ReportsTable reports={reports} />
                    : creatingReports
                        ? <LoadingButton message={'Creating...'}/>
                        : <>
                            <p className="m-4">No reports have been run for {longFormattedMonth} {year}.</p>
                            <button
                            className="btn btn-primary"
                            onClick={handleRunReports}
                            disabled={!ability.can('queue', 'Reports')}>
                            Run Reports
                            </button>
                        </>
                }

            </div>
        </div>
    )
}

const LoadingButton = ({ message }: { message: string }) => (
    <button
        className="btn btn-primary"
        type="button"
        disabled
    >
        <span
            className="spinner-border spinner-border-sm me-3"
            role="status"
            aria-hidden="true"
        />
        { message }
    </button>
)
