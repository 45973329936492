import React from 'react'

import { VendorCode } from '../../models'

interface VendorsTableProps {
    vendors: VendorCode[]
}

export const VendorsTable = (props: VendorsTableProps) => {
    const { vendors } = props

    return (
        <table className='datatable table'>
            <tbody>
                <tr>
                    <th>Vendor ID</th>
                    <th>Vendor Code</th>
                </tr>
                {vendors.map(vendor =>
                    <tr key={vendor.id} >
                        <td>{vendor.id}</td>
                        <td>{vendor.code}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
