import React from 'react'
import { Link } from 'react-router-dom'

import { PaymentMethod, Record } from '../../models'
import { currencyFormat } from '../../helpers/currencyCalculation'

interface RecordTableProps {
    record: Record
}

export const RecordTable = (props: RecordTableProps) => {
    const { record } = props

    return (
        <table className='table datatable'>
            <tbody>
                <tr>
                    <th>Status</th>
                    <td>{record.status.name}</td>
                </tr>
                {record.substatus &&
                    <tr>
                        <th>Substatus</th>
                        <td>{record.substatus.name}</td>
                    </tr>
                }
                <tr>
                    <th>Supplier</th>
                    <td>
                        {record.supplier && (
                            <Link to={`/suppliers/${record.supplier?.id}`}>
                                {record.supplier.name}
                            </Link>
                        )}
                        {!record.supplier && record.supplierName}
                    </td>
                </tr>
                <tr>
                    <th>Agency</th>
                    <td>
                        <Link to={`/agencies/${record.agency.id}`}>
                            {record.agency.name}
                        </Link>
                    </td>
                </tr>
                <tr>
                    <th>Upload Date</th>
                    <td>{record.entry.format('M/D/YYYY')}
                    </td>
                </tr>
                <tr>
                    <th>ARC</th>
                    <td>{record.arc}</td>
                </tr>
                <tr>
                    <th>Guest First Name</th>
                    <td>{record.guestFirstName}</td>
                </tr>
                <tr>
                    <th>Guest Last Name</th>
                    <td>{record.guestLastName}</td>
                </tr>
                <tr>
                    <th>Confirmation Number</th>
                    <td>{record.confirmationNumber}</td>
                </tr>
                <tr>
                    <th>Record Phone Number</th>
                    <td>{record.phone}</td>
                </tr>
                <tr>
                    <th>Check In</th>
                    <td>{record.startDate.format('M/D/YYYY')}</td>
                </tr>
                <tr>
                    <th>Check Out</th>
                    <td>{record.endDate.format('M/D/YYYY')}</td>
                </tr>
                <tr>
                    <th>Days</th>
                    <td>{record.days}</td>
                </tr>
                <tr>
                    <th>Rate</th>
                    <td>{currencyFormat(record.rate)}</td>
                </tr>
                <tr>
                    <th>Commission</th>
                    <td>{currencyFormat(record.commission)}</td>
                </tr>
                <tr>
                    <th>Date Processed</th>
                    <td>
                        {record.dateProcessed
                            && record.dateProcessed.format('M/D/YYYY')
                        }
                        {!record.dateProcessed && 'Not Processed'}
                    </td>
                </tr>
                {record.isPaid &&
                    <>
                        {record.isForeign &&
                            <tr>
                                <th>Foreign Value</th>
                                <td>
                                    {(record.forval > 0) && currencyFormat(record.forval)}
                                    {!record.forval && 'Foreign value not recorded'}
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>Paid CT</th>
                            <td>{currencyFormat(record.paidCt)}</td>
                        </tr>
                        <tr>
                            <th>Payment Method</th>
                            <td>{record?.paymentMethod?.name ?? 'Empty'}</td>
                        </tr>
                        {record.paymentMethod
                            && PaymentMethod.CHECK_METHODS.includes(record.paymentMethod.id)
                            && (
                                <tr>
                                    <th>Check Number</th>
                                    <td>{record.checkNumber}</td>
                                </tr>
                            )
                        }
                    </>
                }
            </tbody>
        </table>
    )
}
