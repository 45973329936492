import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { syncSupplierAddresses, SyncSupplierAddressesParams } from '../../../api'
import { TooltipWrapper } from '../../common'
import { getAuthUser } from '../../../helpers'
import { Address, JsonAddress } from '../../../models'
import { AddSupplierAddress } from './add'
import { EditSupplierAddress } from './edit'
import { SupplierAddressTable } from './table'
import defineAbilityFor from './defineAbility'

interface CardProps {
    addresses: Address[]
    supplierId: number
}

export const SupplierAddressesCard = (props: CardProps) => {
    const { supplierId } = props

    const [isAdding, setIsAdding] = useState(false)
    const [addressToEdit, setAddressToEdit] = useState<Address | undefined>()
    const [addresses, setAddresses] = useState(props.addresses)

    const ability = defineAbilityFor(getAuthUser())

    const supplierAddressCallback = (params: SyncSupplierAddressesParams) => {
        syncSupplierAddresses(supplierId.toString(), params)
            .then((response) => {
                const { data, status } = response

                if (status === 200 || status === 201) {
                    const { addresses } = data

                    setAddresses(addresses.map(
                        (address: JsonAddress) => new Address(address)
                    ))

                    toast.success('Supplier Addresses Updated')

                    setIsAdding(false)
                    setAddressToEdit(undefined)
                }
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <div className='container card mb-5'>
            <div className='
                card-body
                col-xs-12
                row
                d-flex
                flex-row
                justify-content-between
            '>
                <div className='row flex-row mb-5 pe-0'>
                    <h3 className='ps-0 d-inline-flex card-title w-50 uppercase'>
                        Addresses
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                    {!isAdding && !addressToEdit &&
                        <TooltipWrapper
                            condition={!ability.can('Create', 'SupplierAddress')}
                            element={
                                <button
                                    className='btn btn-primary'
                                    disabled={!ability.can('Create', 'SupplierAddress')}
                                    onClick={() => setIsAdding(true)}>
                                    Add New Address
                                </button>
                            }
                            message='Insufficient permissions' />
                    }
                    {isAdding &&
                        <button
                            className='btn btn-outline-secondary'
                            onClick={() => setIsAdding(false)}>
                            Cancel
                        </button>
                    }
                    {addressToEdit &&
                        <button
                            className='btn btn-outline-secondary'
                            onClick={() => setAddressToEdit(undefined)}>
                            Cancel
                        </button>
                    }
                    </div>
                </div>
                <div className='row flex-row p-0'>
                {!isAdding && !addressToEdit &&
                    <SupplierAddressTable
                        addresses={addresses}
                        setAddresses={setAddresses}
                        setAddressToEdit={setAddressToEdit}
                        supplierId={supplierId} />
                }
                {isAdding &&
                    <AddSupplierAddress
                        syncSupplierAddresses={supplierAddressCallback} />
                }
                {addressToEdit &&
                    <EditSupplierAddress
                        address={addressToEdit}
                        syncSupplierAddresses={supplierAddressCallback} />
                }
                </div>
            </div>
        </div>
    )
}
