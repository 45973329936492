import React from 'react'

import { defineAbilityFor } from './'
import { TooltipWrapper, CancelIcon, SuccessIcon } from '../common'
import { downloadAgencyReconciliationReport } from '../../api/routes'
import { getAuthUser } from '../../helpers'
import { Report } from '../../models/report'

interface ReportTableProps {
    reports: Report[]
}

export const ReportsTable = (props: ReportTableProps) => {
    const { reports } = props

    const ability = defineAbilityFor(getAuthUser())

    return (
        <table className="datatable table">
            <tbody>
                <tr>
                    <th>AGENCY ID</th>
                    <th>AGENCY</th>
                    <th className="text-center">STATUS</th>
                    <th className="text-center">ACTION</th>
                </tr>
                {reports.map((r: Report) => (
                    <tr key={r.id}>
                        <td>{r.agency_id}</td>
                        <td>{r.agency}</td>
                        {getStatusIndicator(r)}
                        <td className="text-center">
                            <TooltipWrapper
                                condition={!ability.can('download', 'Reports')}
                                message="You don't have the required permissions to download agency reconciliation reports."
                                element={
                                    r.completed || r.succeeded ? (
                                        <a
                                            href={`${downloadAgencyReconciliationReport}/${r.agency_id}`}
                                            className={`btn btn-primary ${!ability.can('download', 'Reports') ? 'disabled' : ''}`}
                                        >
                                            Download
                                        </a>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        </td>
                        <></>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
const getStatusIndicator = (report: Report) => {
    if (report.completed === false) {
        return (
            <td className="text-center">
                <div
                    className="spinner-border spinner-border-sm text-secondary"
                    role="status"
                >
                    <span
                        className="visually-hidden">
                        Working...
                    </span>
                </div>
            </td>
        )
    }
    if (report.succeeded === false) {
        return (
            <td className="text-center">
                <CancelIcon />
            </td>
        )
    }
    return (
        <td className="text-center">
            <SuccessIcon />
        </td>
    )
}
