import React from 'react'
import { Link } from 'react-router-dom'

import { SupplierBalance } from '../../../models'
import { PaginatedTableContract } from '../../common'
import { currencyFormat } from '../../../helpers/currencyCalculation'

interface SupplierBalancesTableProps extends PaginatedTableContract {
    resources: {
        supplierBalances: SupplierBalance[]
    }
}

export const SupplierBalancesTable = (props: SupplierBalancesTableProps) => {
    const { resources } = props
    const { supplierBalances } = resources

    return (
        <table className='datatable table'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Balance</th>
                    <th>Last Updated</th>
                </tr>
                {supplierBalances.map(supplierBalance =>
                    <tr key={supplierBalance.id}>
                        <td>
                            <Link to={`/suppliers/${supplierBalance.supplierId}`}>
                                {supplierBalance.supplierName}
                            </Link>
                        </td>
                        <td>{supplierBalance.supplierPhone}</td>
                        <td>
                            {currencyFormat(supplierBalance.balance)}
                        </td>
                        <td>
                            {supplierBalance.updated_at.format('MMM DD, YYYY')}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
