import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface Option {
    value: string
    label: string
}
interface InputConfig {
    id: string
    name: string
    placeholder: string
    type: string
    label?: string
    options?: Option[]
}

interface AdvancedSearchProps {
    setAdvancedSearch: (values: Record<string, any>) => void,
    inputs: InputConfig[],
    defaultState: Record<string, any>
}

export const AdvancedSearch: React.FC<AdvancedSearchProps> = ({ setAdvancedSearch, inputs, defaultState }) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [localState, setLocalState] = useState(defaultState)

    useEffect(() => {
        const filters: Record<string, any> = {}
        searchParams.forEach((value, key) => {
            filters[key] = value
        })

        setLocalState(prevState => ({ ...prevState, ...filters }))
    }, [searchParams.toString()])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target
        setLocalState(prevState => ({ ...prevState, [name]: value }))
    }
    type Timer = ReturnType<typeof setTimeout>
    const timeoutRef = useRef<Timer>()

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            setAdvancedSearch(localState)

            const params = new URLSearchParams()

            for (const [key, value] of Object.entries(localState)) {
                if (value && searchParams.has(key)) {
                    params.set(key, value)
                }
            }
            setSearchParams(params)
        }, 500)
    }, [JSON.stringify(localState)])

    const handleClear = () => {
        setLocalState(defaultState)
        setAdvancedSearch(defaultState)
    }

    return (
        <>
            {inputs.map((input) => (
                <div className="col-md-3 mt-3" key={input.id}>
                    {input.label && <label htmlFor={input.id}>{input.label}</label>}
                    {input.type !== 'dropdown' ? (
                        <input
                            type={input.type}
                            id={input.id}
                            name={input.name}
                            value={localState[input.name]}
                            placeholder={input.placeholder}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    ) : (
                        <select
                            id={input.id}
                            name={input.name}
                            value={localState[input.name]}
                            onChange={handleInputChange}
                            className="form-control"
                        >
                            <option value="">{input.placeholder}</option>
                            {input.options?.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
            ))}
            <div className="col-md-2 mt-3 d-flex align-items-end">
                <button type="button" className="btn btn-secondary" onClick={handleClear}>
                    Clear
                </button>
            </div>
        </>
    )
}
