import React from 'react'
import { Link } from 'react-router-dom'

import { Invoice } from '../../models'
import { PaginatedTableContract } from '../common'
import { currencyFormat } from '../../helpers/currencyCalculation'

interface InvoicesTableProps extends PaginatedTableContract {
    resources: {
        invoices: Invoice[]
    }
}

export const InvoicesTable = (props: InvoicesTableProps) => {
    const { resources } = props
    const { invoices } = resources

    return (
        <table className='datatable table'>
            <tbody>
                <tr>
                    <th>ID</th>
                    <th>Supplier</th>
                    <th>Total</th>
                    <th>Reconcile</th>
                    <th>Completed</th>
                    <th>Last Updated</th>
                </tr>
                {invoices.map(invoice =>
                    <tr key={invoice.id}>
                        <td>
                            <Link to={`/invoices/${invoice.id}`}>
                                {invoice.id}
                            </Link>
                        </td>
                        <td><Link to={`/suppliers/${invoice.supplier.id}`}>{invoice.supplier.name}</Link></td>
                        <td>
                            {currencyFormat(invoice.total)}
                        </td>
                        <td>
                            <Link
                                to={`/invoices/reconciliation/${invoice.id}`}
                                className='btn btn-primary'>
                                Reconcile
                            </Link>
                        </td>
                        <td>
                            {invoice.completed ? 'Yes' : 'No'}
                        </td>
                        <td>{invoice.updated_at.format('MMM DD, YYYY')}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
