import React from 'react'

import { AgencyPayment } from '../../../models'
import { currencyFormat } from '../../../helpers/currencyCalculation'

interface AgencyPaymentsTableProps {
    payments: AgencyPayment[]
}

export const AgencyPaymentsTable = (props: AgencyPaymentsTableProps) => {
    const { payments } = props

    if (payments.length === 0) {
        return <p>No payments found</p>
    }

    return (
        <table className='table datatable'>
            <thead>
                <tr>
                    <th>Payment Date</th>
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Paid By</th>
                </tr>
            </thead>
            <tbody>
            {payments
                .sort((a, b) => b.id - a.id)
                .map(payment => (
                    <tr key={payment.id}>
                        <td>{payment.created_at.format('MMMM D, YYYY')}</td>
                        <td>{payment.paymentMethod}</td>
                        <td>
                            {currencyFormat(payment.amount)}
                        </td>
                        <td>{payment.createdBy}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
